export const COURSE_CATEGORIES = [
  { name: 'Languages', slug: 'languages', hasImages: true },
  { name: 'Marketing', slug: 'marketing', hasImages: true },
  { name: 'Data', slug: 'data', hasImages: true },
  { name: 'Finance', slug: 'finance', hasImages: true },
  { name: 'People and HR', slug: 'people-and-hr', hasImages: true },
  { name: 'Leadership', slug: 'leadership', hasImages: true },
  { name: 'Product Management', slug: 'product-management', hasImages: true },
  { name: 'Business', slug: 'business', hasImages: true },
  { name: 'Cloud Engineering', slug: 'cloud-engineering', hasImages: true },
  { name: 'Design', slug: 'design', hasImages: true },
  { name: 'Devops', slug: 'devops', hasImages: true },
]

export const COURSE_TYPES = [
  { name: 'Bootcamp', id: 'bootcamp', translationKey: 'bootcamp' },
  { name: 'Short Course', id: 'short-course', translationKey: 'short-course' },
  { name: 'Master', id: 'master', translationKey: 'master' },
  {
    name: 'Certification',
    id: 'certification',
    translationKey: 'certification',
  },
  {
    name: 'Language Course',
    id: 'language-course',
    translationKey: 'language-course',
  },
]

// TODO: translate
export const COURSE_DURATIONS = [
  { name: '< 1 year', id: '365', translationKey: 'less-than-one-year' },
  { name: '< 6 months', id: '182', translationKey: 'less-than-six-months' },
  { name: '< 1 month', id: '30', translationKey: 'less-than-one-month' },
  { name: '< 3 days', id: '3', translationKey: 'less-than-three-days' },
]
