import React from 'react'

import useCustomFonts from '@/app/hooks/useCustomFonts'

export const FragmentStyleContainer = ({ children }) => {
  const { inter, planarGt } = useCustomFonts()
  return (
    <div className={`${planarGt.variable} ${inter.variable} font-sans`}>
      {children}
    </div>
  )
}
