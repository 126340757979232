import { IEducationProviderAgreement } from './agreement'
import { ICategory } from './category'
import { IEducationProvider } from './education-provider'
import { IFinancialTerm } from './financial-term'

export interface IInstructor {
  name: string
  linkedIn: string
  website: string
  avatarUrl: string
  role: string
  company: string
  bio: string
}

interface ICost {
  amount?: number
  currency: string
}

export enum DurationType {
  Hour = 'hour',
  Hours = 'hours',
  Day = 'day',
  Days = 'days',
  Week = 'week',
  Weeks = 'weeks',
  Month = 'month',
  Months = 'months',
  Year = 'year',
  Years = 'years',
}

export interface IDuration {
  quantity: number
  type: DurationType
  quantityInDays: number
}

export interface ICohort {
  _id: string
  location: string
  startDate: Date
  endDate: Date
  format: string
  cost?: ICost | null
  classType: string
  duration: IDuration
}

interface ITestimonial {
  comment: string
  author: string
  authorRole: string
  avatarUrl: string
}

interface IProgrammeStructure {
  title: string
  subtitle?: string
  description: string
}

interface ITarget {
  title: string
  description: string
}

interface ICourseMotivation {
  videoUrl: string
  motivation: string
  advisor: {
    name: string
    role: string
    avatarUrl: string
  }
}

export interface ICourse {
  _id?: string
  name: string
  externalId?: string
  educationProvider: IEducationProvider | string
  agreement?: IEducationProviderAgreement | string
  agreements?: IEducationProviderAgreement[] | string[]
  categories: string[] | ICategory[]
  subCategories: string[]
  suggestedCategory?: string
  suggestedSubCategories?: string[]
  cohorts?: ICohort[]
  type: string
  targets?: ITarget[]
  prerequisites: string[]
  skills: string[]
  url: string
  imageUrl: string
  summary: string
  learningAreas?: string[]
  learningDescription: string
  language?: string
  level?: string
  instructors: IInstructor[]
  note?: string
  metadata?: string
  validationType?: ValidationType
  commentsToValidation?: string
  videoUrl?: string
  brochureUrl?: string
  achievements?: string[]
  testimonials?: ITestimonial[]
  programmeStructure?: IProgrammeStructure[]
  courseMotivation?: ICourseMotivation
  hasCertificate?: boolean
  published?: boolean
  // TODO: remove when change the marketplace to work with cohorts.
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  financialTerms?: IFinancialTerm[]
}

export interface ICourseWithEducationProvider extends ICourse {
  educationProvider: IEducationProvider
}
