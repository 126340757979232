export const moneyToInteger = (value) => {
  if (!value) {
    return 0
  }

  return Math.round(parseFloat(value) * 100)
}

export const integerToMoney = (value) => {
  if (!value) {
    return 0
  }

  return parseFloat((parseInt(value) / 100).toFixed(2))
}
