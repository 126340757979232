import { Autocomplete, TextField } from '@mui/material'
import { AutoCompleteProps } from './AutoComplete.types'

export const AutoComplete = ({
  title,
  subTitle,
  options = [],
  value,
  onChange,
}: AutoCompleteProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="font-title">{title}</div>
      <div className="-mt-1 text-xs text-gray-light">{subTitle}</div>

      <Autocomplete
        options={options}
        filterSelectedOptions
        getOptionLabel={(option) =>
          option?.name ?? options.find((op) => op.id === option.id)?.name ?? ''
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={value}
        onChange={(_, newValue) => {
          onChange(newValue)
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: '8px',
                border: '1px solid #E2E3E6',
                minHeight: '32px',
                borderRadius: '8px',
                '&:hover': {
                  border: '1px solid #292929',
                },
                '& input:focus': {
                  boxShadow: 'none',
                },
              },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            {...params}
          />
        )}
      />
    </div>
  )
}
