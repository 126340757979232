import { BadgeProps } from './Badge.types'

// TODO: replace with Chip component
export const Badge = ({ type, color, children, className }: BadgeProps) => {
  return (
    <div
      className={`text-xxs w-fit whitespace-nowrap ${color} ${type} ${className}`}
    >
      {children}
    </div>
  )
}
