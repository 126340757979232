export enum BadgeColors {
  YELLOW = 'bg-mydra-yellow text-black',
  GRAY = 'bg-mydra-medium-gray text-black',
  GRAY_LIGHT = 'bg-grey-background text-gray-light',
  WHITE = 'bg-white text-black',
  RED = 'bg-mydra-light-red text-black',
  GREEN = 'bg-mydra-light-green text-black',
  VIOLET = 'bg-mydra-violet text-black',
  BLUE = 'bg-mydra-light-blue text-black',
  ORANGE = 'bg-mydra-light-orange text-black',
  CORAL = 'bg-mydra-coral text-black',
  EDP_GREEN = 'bg-edp-green text-black',
}

export enum BadgeTypes {
  ROUND_SMALL = 'rounded-lg px-2 py-1 text-xs',
  ROUND = 'rounded-3xl px-5 py-1.5',
  SQUARE = 'rounded-lg px-2.5 py-1.5',
}

export interface BadgeProps {
  children: React.ReactNode
  color: BadgeColors
  type: BadgeTypes
  className?: string
}
