import { InfoIcon } from '@/app/icons/InfoIcon'
import { Popper, PopperPlacementType } from '@mui/base'
import { FragmentStyleContainer } from '@/app/components/FragmentStyleContainer'
import { useClickAway } from '@uidotdev/usehooks'
import React from 'react'

type InfoTooltipOpts = {
  children: React.ReactNode
  placement?: PopperPlacementType
  popupProps?: React.HTMLAttributes<HTMLDivElement>
  label?: React.ReactNode
  icon?: React.ReactNode
  openOnHover?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export function InfoTooltip({
  children,
  placement,
  popupProps = {},
  label = null,
  icon = <InfoIcon />,
  openOnHover = false,
  ...props
}: InfoTooltipOpts) {
  const [anchorEl, setAnchorEl] = React.useState<
    null | undefined | HTMLElement
  >(null)
  const ref = useClickAway(() => {
    setAnchorEl(null)
  })
  const fallbackRef = React.useRef<HTMLDivElement>(null)

  const placeTooltip = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(
      anchorEl
        ? null
        : event.currentTarget?.parentElement?.parentElement ??
            fallbackRef.current
    )
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const IconElement = icon
    ? () =>
        React.cloneElement(icon, {
          className: 'w-4 h-4',
        })
    : () => null

  return (
    <div
      {...props}
      onPointerEnter={(event) => {
        if (openOnHover) {
          placeTooltip(event)
        }
      }}
      onPointerLeave={() => {
        if (openOnHover) {
          setAnchorEl(null)
        }
      }}
      ref={fallbackRef}
    >
      {openOnHover ? (
        <>
          {label}
          <IconElement />
        </>
      ) : (
        <button onClick={placeTooltip}>
          {label}
          <IconElement />
        </button>
      )}
      <Popper
        placement={placement}
        id={id}
        open={open}
        anchorEl={anchorEl}
        className="z-50"
      >
        <FragmentStyleContainer>
          <div
            {...popupProps}
            ref={ref}
            className={`text-sm px-3 py-2 m-2 bg-white rounded max-w-52 shadow-tooltip ${popupProps?.className ?? ''}`}
          >
            {children}
          </div>
        </FragmentStyleContainer>
      </Popper>
    </div>
  )
}
