import { COURSE_CATEGORIES } from '../constants'

export const getCardImage = (course) => {
  if (!course) {
    return '/images/card-cover-images/default-01.jpg'
  }

  const { _id: id, category, imageUrl = '' } = course

  if (imageUrl) {
    return imageUrl
  }

  const numbers = id?.match(/\d+/g)
  const sum = numbers.reduce((acc, current) => acc + parseInt(current, 10), 0)
  const slug = category?.slug ?? 'default'

  const hasImages = COURSE_CATEGORIES.find((c) => c.slug === slug)?.hasImages

  if (hasImages) {
    const index = (sum % 4) + 1
    return `/images/card-cover-images/${slug}-0${index}.jpg`
  } else {
    const index = (sum % 3) + 1
    return `/images/card-cover-images/default-0${index}.jpg`
  }
}
