import { DurationType, ICohort, ICourse, IDuration } from '@/types/course'
import { integerToMoney } from '../lib/moneyHandler'
import { useTranslations } from 'next-intl'
import pluralize from 'pluralize'

export function useFormatters() {
  const t = useTranslations('Formatters')
  const CURRENCY_REGEX = /^[A-Z]{3}$/i

  function formatDate(
    value: string,
    dateStyle:
      | 'long'
      | 'short'
      | 'full'
      | 'medium'
      | '2-digit'
      | 'long-month-numeric-year'
      | undefined
  ) {
    if (!value || !dateStyle || Number.isNaN(Date.parse(value))) {
      return ''
    }

    if (dateStyle === '2-digit') {
      return new Intl.DateTimeFormat(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }).format(new Date(value))
    }
    if (dateStyle === 'long-month-numeric-year') {
      return new Intl.DateTimeFormat(undefined, {
        month: 'long',
        year: 'numeric',
      }).format(new Date(value))
    }
    return new Intl.DateTimeFormat(undefined, { dateStyle }).format(
      new Date(value)
    )
  }

  function formatCurrencyValue(
    value: string,
    currency: string,
    formatOptions = {}
  ) {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: CURRENCY_REGEX.test(currency) ? currency : 'EUR',
      maximumFractionDigits: 0,
      ...formatOptions,
    }).format(integerToMoney(value))
  }

  function formatCurrencyValueToParts(
    value: string,
    currency: string,
    formatOptions = {}
  ) {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: CURRENCY_REGEX.test(currency) ? currency : 'EUR',
      maximumFractionDigits: 0,
      ...formatOptions,
    }).formatToParts(integerToMoney(value))
  }

  function formatCoursePrice(course, formatOptions = {}) {
    const { cost } = course || {}
    const { amount, currency } = cost || {}

    if (!cost) {
      return t('price-request')
    }

    return Number.isInteger(amount)
      ? amount === 0
        ? t('free')
        : formatCurrencyValue(amount.toString(), currency, formatOptions)
      : t('price-request')
  }

  function truncateCourseTitle(title: string) {
    if (!title) {
      return ''
    }

    if (title.length > 54) {
      return `${title.substring(0, 50)}...`
    }

    return title
  }

  const durationToDays = (duration: IDuration): number => {
    switch (duration.type) {
      case DurationType.Hour:
      case DurationType.Hours:
        return duration.quantity / 24
      case DurationType.Day:
      case DurationType.Days:
        return duration.quantity
      case DurationType.Week:
      case DurationType.Weeks:
        return duration.quantity * 7
      case DurationType.Month:
      case DurationType.Months:
        return duration.quantity * 30 // assuming 1 month is 30 days for simplicity
      case DurationType.Year:
      case DurationType.Years:
        return duration.quantity * 365 // assuming 1 year is 365 days for simplicity
      default:
        return NaN
    }
  }

  const formatCourseDuration = (course: Pick<ICourse, 'cohorts'>): string => {
    if (!course.cohorts || course.cohorts.length === 0) {
      return ''
    }

    let maxDuration: IDuration | null = null
    let maxDays = -1

    for (const cohort of course.cohorts) {
      if (!cohort.duration) {
        continue
      }

      const currentDays = durationToDays(cohort.duration)
      if (isNaN(currentDays)) {
        continue
      }
      if (currentDays > maxDays) {
        maxDuration = cohort.duration
        maxDays = currentDays
      }
    }

    if (!maxDuration) {
      return ''
    }

    const durationString = t(pluralize(maxDuration.type, maxDuration.quantity))

    return t('upToDuration', {
      quantity: maxDuration.quantity,
      duration: durationString,
    })
  }

  function formatCohortDuration(cohort: Pick<ICohort, 'duration'>) {
    const { duration } = cohort ?? {}

    if (!duration || !duration.quantity || !duration.type) {
      return '-'
    }

    const durationString = t(
      pluralize(cohort.duration.type, cohort.duration.quantity)
    )

    return t('duration', {
      quantity: cohort.duration.quantity,
      duration: durationString,
    })
  }

  return {
    formatDate,
    formatCurrencyValue,
    formatCoursePrice,
    truncateCourseTitle,
    formatCourseDuration,
    formatCohortDuration,
    formatCurrencyValueToParts,
  }
}
