import * as React from 'react'

const Spinner = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44 44"
    fill="none"
    {...props}
  >
    <circle cx={22} cy={22} r={20} stroke="#C2C2C2" strokeWidth={4} />
    <path
      stroke="currentColor"
      strokeWidth={4}
      d="M2 22C2 10.954 10.954 2 22 2"
    />
  </svg>
)

export default Spinner
