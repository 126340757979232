import Spinner from '@/app/icons/Spinner/Spinner'
import React from 'react'

interface ButtonProps {
  onClick?: (e: React.SyntheticEvent) => void
  isLoading?: boolean
  disabled?: boolean
  color?: 'purple' | 'black' | 'white'
  size?: 'small' | 'medium'
  type?: 'button' | 'submit' | 'reset'
  children: string | React.ReactNode
  className?: string
  fullWidth?: boolean
}

const colorClasses = {
  purple: 'bg-mydra-purple text-white hover:bg-mydra-dark-purple',
  black: 'bg-mydra-black text-white hover:bg-opacity-[85%]',
  white:
    'bg-white text-mydra-black outline outline-1 outline-[--lines] hover:bg-lines',
}

const sizeClasses = {
  small:
    'px-2 h-10 text-sm font-medium rounded-lg flex items-center justify-center',
  medium:
    'px-4 h-12 text-base font-medium rounded-xl flex items-center justify-center',
}

export const Button = ({
  onClick,
  isLoading = false,
  disabled = false,
  color = 'black',
  size = 'medium',
  type = 'button',
  fullWidth = false,
  className,
  children,
}: ButtonProps) => {
  return (
    <button
      data-testid="button"
      className={`hover:cursor-pointer w-fit ${colorClasses[color]} ${sizeClasses[size]} ${fullWidth ? 'w-full' : ''} ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
      disabled={disabled || isLoading}
      type={type}
      onClick={onClick}
    >
      {isLoading ? (
        <div className="overflow-hidden w-6 h-6">
          <Spinner className="animate-spin" />
        </div>
      ) : (
        children
      )}
    </button>
  )
}
