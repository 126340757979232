import Image, { ImageProps } from 'next/image'
import { useState, useEffect, forwardRef } from 'react'

type ImageWithFallbackProps = {
  fallback?: React.ReactNode
  fallbackSrc?: string
} & ImageProps

export const ImageWithFallback = forwardRef<
  HTMLImageElement,
  ImageWithFallbackProps
>(({ fallback = null, fallbackSrc, src, ...props }, ref) => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setHasError(false)
  }, [src])

  return hasError && fallback !== undefined && !fallbackSrc ? (
    fallback
  ) : (
    <Image
      ref={ref}
      key={hasError.toString()}
      {...props}
      alt={props.alt || 'Image'}
      src={hasError || (!src && fallbackSrc) ? fallbackSrc ?? '' : src ?? ''}
      onError={() => setHasError(true)}
    />
  )
})

ImageWithFallback.displayName = 'ImageWithFallback'
